import type { Tables } from '~~/types/supabase'

export interface RenderedCategory {
  name: string
  slug: string
  icon: string | null
  published: boolean
  children: RenderedCategory[]
}

export const getChildCategories = (
  categories: Tables<'asset_categories'>[],
  parentId: number | null
): Tables<'asset_categories'>[] => {
  return categories
    .filter(category => category.parent_id === parentId)
    .sort((a, b) => a.sort_order - b.sort_order)
}

export const renderCategory = (
  category: Tables<'asset_categories'>,
  allCategories: Tables<'asset_categories'>[]
): RenderedCategory => {
  const children = getChildCategories(allCategories, category.id)

  return {
    name: category.name,
    slug: category.slug,
    icon: category.icon,
    published: category.published,
    children: children.map(child => renderCategory(child, allCategories))
  }
}

export const buildMenuItems = (categories: Tables<'asset_categories'>[]) => {
  return getChildCategories(categories, null).map(category =>
    renderCategory(category, categories)
  )
}

export const useCategoryMenu = (
  getAssetCategories: () => Promise<Tables<'asset_categories'>[]>
) => {
  const categories = useState<Tables<'asset_categories'>[]>(
    'assetCategories',
    () => []
  )
  const loading = ref(true)
  const error = ref<ErrorLike | null>(null)

  const fetchCategories = async () => {
    loading.value = true

    try {
      categories.value = await getAssetCategories()
      error.value = null
    } catch (e) {
      console.error('Failed to fetch categories', e)

      categories.value = []
      error.value = isErrorLike(e) ? e : new Error('Unknown error occurred')
    } finally {
      loading.value = false
    }
  }

  const menuItems = computed(() => buildMenuItems(categories.value))

  return { categories, loading, error, fetchCategories, menuItems }
}
