<script setup lang="ts">
import type { FormSubmitEvent } from '#ui/types'
import { z } from 'zod'

const { canView } = useACL()
const { profile, fetchProfile } = useProfiles()
const {
  getCurrentUserNotifications,
  markCurrentUserVisibleNotificationsAsRead,
  currentUserNotifications,
  unreadCount
} = useNotifications()

const canViewProducts = computed(() => canView('products'))

const open = ref(false)
const openSearchModal = ref(false)

onMounted(async () => {
  await Promise.all([fetchProfile(), getCurrentUserNotifications()])
})

// When the notification popup is closed, automatically mark all visible notifications as read
watch(open, async newVal => {
  if (!newVal) {
    await markCurrentUserVisibleNotificationsAsRead()
  }
})

const schema = z.object({
  query: z.string().min(1)
})

type Schema = z.output<typeof schema>

const query = useRoute().query['query']

const state = reactive({
  query: (Array.isArray(query) ? query[0] : query) ?? ''
})

async function handleSearch(event: FormSubmitEvent<Schema>) {
  const query = event.data.query
  if (!query) return
  await navigateTo({
    path: '/search',
    query: {
      query: event.data.query
    }
  })
}

async function handleSearchAndCloseModal(event: FormSubmitEvent<Schema>) {
  await handleSearch(event)
  openSearchModal.value = false
}
</script>

<template>
  <header
    class="relative z-10 flex items-center bg-surface pr-5 shadow-md dark:bg-surface-dark"
  >
    <NuxtLink class="ml-[2.5rem] w-56" to="/">
      <span class="sr-only">Home</span>
      <CommonLogo class="py-2" data-app-tour-step="0" />
    </NuxtLink>
    <!-- Search form for md and larger screens -->
    <div class="hidden md:block">
      <UForm :schema="schema" :state="state" @submit.prevent="handleSearch">
        <UInput
          v-model="state.query"
          icon="i-heroicons-magnifying-glass-20-solid"
          size="xl"
          color="white"
          :trailing="false"
          name="query"
          placeholder="Search..."
          data-app-tour-step="4"
        />
      </UForm>
    </div>

    <!-- Search button for smaller screens -->
    <div class="mx-8 md:hidden">
      <UButton
        icon="i-heroicons-magnifying-glass-20-solid"
        variant="soft"
        :ui="{ rounded: 'rounded-full' }"
        @click="openSearchModal = true"
      />
    </div>
    <div class="ml-auto flex items-center gap-4">
      <UButton
        icon="i-heroicons-globe-alt"
        class="md:hidden"
        color="gray"
        square
        variant="solid"
        :ui="{ rounded: 'rounded-full' }"
        to="https://adventurematt.com/"
      />
      <NuxtLink
        external
        href="https://adventurematt.com/"
        target="_blank"
        rel="noopener noreferrer"
        class="hidden leading-7 text-on-surface md:inline-block dark:text-on-surface-dark"
      >
        adventurematt.com
      </NuxtLink>
      <UPopover
        v-model:open="open"
        :popper="{ placement: 'bottom-end' }"
        data-app-tour-step="5"
      >
        <UChip :show="unreadCount > 0" :text="unreadCount" size="2xl">
          <UserAvatar v-if="profile" :user="profile" />
        </UChip>
        <template #panel>
          <UCard>
            <template #header>
              <div class="flex items-center justify-between gap-8">
                <h3>Notifications</h3>
                <UButton
                  label="Mark all as read"
                  variant="ghost"
                  @click="markCurrentUserVisibleNotificationsAsRead"
                />
              </div>
            </template>
            <template v-if="currentUserNotifications.length">
              <div
                v-for="notification in currentUserNotifications"
                :key="`user-notification-${notification.id}`"
              >
                <Notification
                  :notification="notification"
                  @navigate="open = false"
                />
              </div>
            </template>
            <p v-else class="text-center text-gray-500">No new notifications</p>
            <template #footer>
              <UButton
                to="/settings"
                label="My Account"
                variant="ghost"
                @click="open = false"
              />
              <UButton
                to="/my-files"
                label="My Files"
                variant="ghost"
                @click="open = false"
              />
            </template>
          </UCard>
        </template>
      </UPopover>
      <ClientOnly>
        <CartIcon v-if="canViewProducts" />
      </ClientOnly>
      <CommonHelpIcon data-app-tour-step="6" />
    </div>
    <UModal v-model="openSearchModal">
      <UCard>
        <template #header>
          <div class="flex items-center justify-between">
            <h3>Search</h3>
            <UButton
              color="gray"
              variant="ghost"
              icon="i-heroicons-x-mark-20-solid"
              class="-my-1"
              @click="openSearchModal = false"
            />
          </div>
        </template>
        <UForm
          :schema="schema"
          :state="state"
          @submit.prevent="handleSearchAndCloseModal"
        >
          <UInput
            v-model="state.query"
            icon="i-heroicons-magnifying-glass-20-solid"
            color="white"
            :trailing="false"
            name="query"
            placeholder="Search..."
          />
        </UForm>
      </UCard>
    </UModal>
  </header>
</template>
