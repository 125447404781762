<!--
  Dark Mode Toggle Component

  State:
  - isDarkMode: Boolean - Current theme state

  Methods:
  - toggleDarkMode: Switches between light and dark modes

  Features to implement:
  - Persist user preference in local storage
  - Implement smooth transition between modes
  - Update relevant CSS variables or classes
-->
<script setup lang="ts">
const colorMode = useColorMode()
const isDark = computed({
  get() {
    return colorMode.value === 'dark'
  },
  set() {
    colorMode.preference = colorMode.value === 'dark' ? 'light' : 'dark'
  }
})
</script>
<template>
  <ClientOnly>
    <label class="flex items-center gap-4">
      <UIcon name="i-heroicons-sun" />
      <UToggle v-model="isDark" size="2xl" />
      <UIcon name="i-heroicons-moon" />
    </label>
    <template #fallback>
      <div class="h-8 w-8" />
    </template>
  </ClientOnly>
</template>
