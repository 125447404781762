<!--
  Notification Component

  Props:
  - notification: Object - Notification data including asset info, status, and date

  Methods:
  - markAsViewed: Updates the notification status to viewed (unsure, but maybe viewing asset is all we need to markAsViewed?)
  - formatDate: Formats the notification date to a relative time string

  Features to implement:
  - Display asset image (consider lazy loading for performance)
  - Create a linked title that navigates to the associated asset
  - Format and display the notification date in a relative format (e.g., "6 months ago")
  - Ensure the component is accessible and keyboard navigable
-->
<script setup lang="ts">
import type { NotificationAction } from '#ui/types'
import type { UserNotification } from '~~/app/composables/useNotifications'

const props = defineProps<{
  notification: UserNotification
}>()
const ui = computed(() => ({
  background: props.notification.read_at
    ? undefined
    : 'bg-brand-200 dark:bg-brand-800'
}))
const emit = defineEmits(['navigate'])

const actions = computed<NotificationAction[]>(() => {
  switch (props.notification.notification.type) {
    case 'Asset Update':
    case 'Asset Upload':
      return [
        {
          variant: 'ghost',
          label: 'View Asset',
          to: `/assets/${props.notification.notification.asset.slug}`,
          click: () => emit('navigate')
        }
      ]

    case 'Product Update':
      return [
        {
          variant: 'ghost',
          label: 'View Product',
          to: `/products/${props.notification.notification.product.id}`,
          click: () => emit('navigate')
        }
      ]

    default:
      console.error(
        'Unsupported notification in the Notification component',
        props.notification
      )
      return []
  }
})
</script>

<template>
  <!-- @vue-expect-error -->
  <UNotification
    :id="notification.notification.id"
    :icon="undefined"
    :timeout="0"
    :title="notification.notification.type"
    :ui="ui"
    :actions="actions"
    :close-button="null"
  >
    <template #description>
      <p v-if="notification.notification.type === 'Asset Update'">
        <strong>Updated:</strong> {{ notification.notification.asset.name }}
      </p>
      <p v-else-if="notification.notification.type === 'Asset Upload'">
        <strong>New:</strong> {{ notification.notification.asset.name }}
      </p>
      <p v-else-if="notification.notification.type === 'Product Update'">
        <strong>Updated:</strong> {{ notification.notification.product.name }}
      </p>
      <p class="mt-2 text-xs">
        {{ new Date(notification.notification.created_at).toLocaleString() }}
      </p>
    </template>
  </UNotification>
</template>
