<script setup lang="ts">
interface MenuItem {
  name: string
  icon: string | null
  slug: string
  published: boolean
  children: MenuItem[]
}

const props = defineProps<{
  item: MenuItem
}>()

const expandedItems = useState<string[]>('expandedNavItems', () => [])
const route = useRoute()
const isExpanded = computed(() => expandedItems.value.includes(props.item.slug))
const hasActiveChild = computed(() => {
  const currentPath = route.path

  // Check if the parent item is active
  if (currentPath.startsWith(`/assets/categories/${props.item.slug}`)) {
    return true
  }

  // Check if any child item is active
  return props.item.children.some(child =>
    currentPath.startsWith(`/assets/categories/${child.slug}`)
  )
})

const toggleExpand = () => {
  if (isExpanded.value) {
    expandedItems.value = expandedItems.value.filter(
      slug => slug !== props.item.slug
    )
  } else {
    expandedItems.value.push(props.item.slug)
  }
}
</script>
<template>
  <div
    v-if="item.published"
    class="nav-item"
    :class="{
      'border border-gray-500 border-opacity-50': isExpanded && !hasActiveChild,
      'border border-brand-500 border-opacity-100': isExpanded && hasActiveChild
    }"
  >
    <div class="flex justify-between px-1 py-2 text-sm">
      <div class="flex items-center gap-1">
        <UIcon v-if="item.icon" :name="item.icon" />
        <ULink
          :to="`/assets/categories/${item.slug}`"
          active-class="text-primary"
          inactive-class="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200"
        >
          {{ item.name }}
        </ULink>
      </div>
      <UIcon
        v-if="item.children.length > 0"
        class="h-5 w-5 cursor-pointer"
        :name="
          isExpanded ? 'i-heroicons-chevron-up' : 'i-heroicons-chevron-down'
        "
        @click="toggleExpand"
      />
    </div>
    <div
      v-if="item.children.length > 0 && isExpanded"
      class="submenu-item bg-black bg-opacity-5"
    >
      <NavigationNavItem
        v-for="child in item.children"
        :key="child.slug"
        :item="child"
      />
    </div>
  </div>
</template>
