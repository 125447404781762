<script setup lang="ts">
const { canEdit, canView } = useACL()
const { logout } = useAuth()

const canEditRetailers = computed(() => canEdit('retailers'))
const canEditUsers = computed(() => canEdit('users'))
const canViewDownloadLogs = computed(() => canView('download logs'))
const canViewEmailStats = computed(() => canView('email statistics'))
</script>

<template>
  <LayoutDashboard>
    <template #nav>
      <h2 class="sidebar-header">Personal</h2>
      <ULink to="/settings" class="my-2">Settings</ULink>
      <ClientOnly>
        <h2
          v-if="
            canEditRetailers ||
            canEditUsers ||
            canViewDownloadLogs ||
            canViewEmailStats
          "
          class="sidebar-header"
        >
          Admin
        </h2>
        <ULink v-if="canViewDownloadLogs" to="/downloads" class="my-2">
          Download Logs
        </ULink>
        <ULink v-if="canViewEmailStats" to="/email-statistics" class="my-2">
          Email Statistics
        </ULink>
        <ULink v-if="canEditRetailers" to="/retailers" class="my-2">
          Manage Retailers
        </ULink>
        <ULink v-if="canEditUsers" to="/users" class="my-2">Manage Users</ULink>
      </ClientOnly>

      <UButton
        class="my-4"
        color="black"
        variant="soft"
        icon="i-heroicons-arrow-left-end-on-rectangle"
        @click="logout"
      >
        Log Out
      </UButton>
      <div class="pt-8" data-app-tour-step="3">
        <CommonDarkModeToggle />
      </div>
    </template>
    <div class="p-8 text-on-background md:pl-12 dark:text-on-background-dark">
      <slot />
    </div>
  </LayoutDashboard>
</template>
