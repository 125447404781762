<script setup lang="ts">
const { tours } = useTours()

const isOpen = ref(false)

const startTour = async () => {
  if (Object.prototype.hasOwnProperty.call(tours, 'app-tour')) {
    isOpen.value = false

    await tours['app-tour']!.start()
  }
}
</script>

<template>
  <div>
    <UButton
      icon="i-heroicons-question-mark-circle"
      color="gray"
      square
      variant="solid"
      :ui="{ rounded: 'rounded-full' }"
      @click="isOpen = true"
    />
    <UModal v-model="isOpen">
      <UCard>
        <template #header>
          <div class="flex items-center justify-between">
            <h3
              class="text-base font-semibold leading-6 text-gray-900 dark:text-white"
            >
              Have a question for us?
            </h3>
            <UButton
              color="gray"
              variant="ghost"
              icon="i-heroicons-x-mark-20-solid"
              class="-my-1"
              @click="isOpen = false"
            />
          </div>
        </template>
        <p class="mb-4">
          Please email
          <ULink
            active-class="text-primary"
            inactive-class="text-primary"
            to="mailto:info@adventurematt.com"
            >info@adventurematt.com</ULink
          >.
        </p>
        <p>
          Want a quick tour of Retail Navigator? Please
          <ULink
            active-class="text-primary"
            inactive-class="text-primary"
            @click="startTour"
            >click here.
          </ULink>
        </p>
      </UCard>
    </UModal>
  </div>
</template>
