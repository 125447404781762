<script setup lang="ts">
const { cart } = useShoppingCart()

const totalItems = computed(() => {
  return Object.values(cart.value).reduce(
    (total, quantity) => total + quantity,
    0
  )
})

const isOpen = ref(false)
</script>

<template>
  <UChip size="2xl" :text="totalItems" :show="totalItems > 0" inset>
    <UButton
      :padded="false"
      color="white"
      size="xl"
      variant="ghost"
      aria-label="Shopping Cart"
      @click="isOpen = true"
    >
      <UIcon name="i-heroicons-shopping-cart" class="h-8 w-8" />
    </UButton>
  </UChip>

  <CartCheckoutForm v-model="isOpen" />
</template>
