<script setup lang="ts">
const emit = defineEmits(['sidebar-closed'])

const { canEdit, canView } = useACL()
const { getAssetCategories } = useAssets()

const canEditAssetCategories = computed(() => canEdit('asset categories'))
const canViewAssetCategories = computed(() => canView('asset categories'))
const canViewProducts = computed(() => canView('products'))

const { loading, error, fetchCategories, menuItems } =
  useCategoryMenu(getAssetCategories)

if (import.meta.client) await fetchCategories()
</script>

<template>
  <aside class="z-10 flex h-full flex-col bg-surface p-4 dark:bg-surface-dark">
    <div class="flex items-center justify-between md:hidden">
      <NuxtLink class="w-56" to="/">
        <span class="sr-only">Home</span>
        <CommonLogo />
      </NuxtLink>
      <UButton
        color="gray"
        variant="ghost"
        icon="i-heroicons-x-mark-20-solid"
        class="-my-1"
        @click="emit('sidebar-closed')"
      />
    </div>
    <div class="mt-auto flex flex-col">
      <ClientOnly>
        <template v-if="canViewAssetCategories">
          <div v-if="loading">Loading...</div>
          <div v-else-if="error">Error: {{ error.message }}</div>
          <div v-else data-app-tour-step="1">
            <div v-for="item in menuItems" :key="item.slug">
              <NavigationNavItem :item="item" />
            </div>
          </div>
        </template>
        <ULink
          v-if="canEditAssetCategories"
          to="/assets/categories"
          active-class="text-primary"
          inactive-class="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200"
        >
          Manage Categories
        </ULink>
        <UButton
          v-if="canViewProducts"
          color="gray"
          to="/products"
          class="my-4"
          data-app-tour-step="2"
        >
          P.O.P. Store
        </UButton>
      </ClientOnly>
      <slot />
    </div>
  </aside>
</template>
