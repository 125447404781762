<script setup lang="ts">
const isSidebarOpen = ref(false)
const router = useRouter()

const toggleSidebar = () => {
  isSidebarOpen.value = !isSidebarOpen.value
}

const closeSidebar = () => {
  isSidebarOpen.value = false
}

watch(
  () => router.currentRoute.value.fullPath,
  () => {
    isSidebarOpen.value = false
  }
)
</script>
<template>
  <aside
    class="fixed z-0 hidden h-screen bg-white md:block md:w-56 dark:bg-surface-dark"
  />
  <div class="dashboard flex min-h-screen flex-col">
    <LayoutTopbar />
    <UButton
      class="md:hidden"
      variant="solid"
      color="gray"
      size="xl"
      icon="i-heroicons-ellipsis-vertical"
      label="Menu"
      @click="toggleSidebar"
    />
    <section id="nav-and-content" class="flex">
      <LayoutSidebar
        :class="[
          'fixed transition-all duration-300 ease-in-out md:relative md:block',
          'inset-y-0 left-0 z-50 w-full bg-white md:mt-5 md:w-56',
          'transform lg:translate-x-0',
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
        ]"
        @sidebar-closed="closeSidebar"
      >
        <slot name="nav" />
      </LayoutSidebar>
      <main class="w-full md:py-12 md:pl-16 md:pr-8">
        <slot />
      </main>
    </section>
  </div>
</template>
